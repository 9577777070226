import React from 'react';
import Navigation from '../sections/header/components/navigation/Navigation';
import Footer from '../sections/footer/Footer';
import { Helmet } from "react-helmet";
import './styles/styles.scss';
import './styles/rodo-styles.scss';

const Rodo = () => {
    const isEng = false;

    return (
        <>
            <Helmet title="RODO | Digital Building Data">
                <link href="https://api.fontshare.com/v2/css?f[]=satoshi@700,500,400&display=swap" rel="stylesheet" />
            </Helmet>
            <div className='rodo'>
                <div className='rodo__container container'>
                    <Navigation engNav={isEng} white="true" />
                    <div className='rodo__header'>
                        <div className='rodo__left'>
                            <h3 className='title'>{isEng ? 'Privacy' : 'Polityka'}</h3>
                            <h3 className='title blue'>{isEng ? 'policy' : 'RODO'}</h3>
                        </div>
                        <div className='rodo__right'>
                            <p>{isEng ? 'Privacy policy of the' : 'Polityka prywatności strony internetowej:'}</p>
                            <p>www.digitalbuildingdata.pl</p>
                            <p>{isEng ? 'website.' : ''}</p>
                        </div>
                    </div>
                    <div className='rodo__content'>
                        <ol>
                            <li className='main-list'>{isEng ? 'General' : 'Postanowienia ogólne'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'The controller of personal data collected via the www.digitalbuildingdata.com website is the company DIGITALBUILDINGDATA SP. Z O.O., with its registered office in Katowice (postal code: 40-850), at ul. Gabriela Narutowicza 16, holder of NIP (tax identification number): 6342985195 and REGON number (National Business Registry) 1387711304, entered in the register of businesses of CEIDG (Central Registration and Information on Business), e-mailing address biuro@digitalbuildingdata.com (hereinafter, “Controller”), who is also a service provider.' :
                                        'Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej www.digitalbuildingdata.pl jest DIGITALBUILDINGDATA SP. ZOO., adres siedziby: ul. Gabriela Narutowicza 16, 40-850 Katowice, NIP: 6342985195, REGON: 1387711304, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, adres poczty elektronicznej: biuro@digitalbuildingdata.com, dalej „Administrator”, będący/a jednoczeście Usługodawcą. , miejsce wykonywania działalności: ul. Gabriela Narutowicza 16, 40-850 Katowice, NIP: 6342985195, REGON: 1387711304, adres poczty elektronicznej (e-mail): biuro@digitalbuildingdata.com, zwany/a dalej „Administratorem”.'}</li>
                                    <li>{isEng ? 'The personal data collected by the Controller through the website are processed in accordance with the applicable law, in particular in accordance with the General Data Protection Regulation No. 2016/679  of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR") and the Personal Data Protection act of 10 May 2018. ' :
                                        'Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.'}</li>
                                </ol>
                            </li>
                            <li className='main-list'>{isEng ? 'Types of processed data, purpose and scope of data processing and data recipients' :
                                'Rodzaj przetwarzanych danych osobowych, cel i zakres zbierania danych'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'Purpose of data processing and legal basis. The Controller processes personal data through the website www.bimmonuments.pl if its users:' :
                                        'Cel przetwarzania i podstawa prawna'}
                                        <ul className='sub-list'>
                                            <li>{isEng ? 'Use the contact form. The personal data are processed pursuant to Art. 6 sec. 1 lit. f GDPR for the purposes of the legitimate interests pursued by the Controller.' :
                                                'skorzystania przez użytkownika z formularza kontaktowego. Dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. f) RODO jako prawnie usprawiedliwiony interes Administratora.'}</li>
                                            <li>{isEng ? 'Sign up for the Newsletter for the purpose of sending commercial email marketing messages. The personal data are processed upon providing by user a separate consent, pursuant to Art. 6 sec. 1 lit. a GDPR' :
                                                'zapisania się przez użytkownika do Newslettera w celu przesyłania informacji handlowych drogą elektroniczną. Dane osobowe są przetwarzane po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO.'}</li>
                                        </ul>

                                    </li>
                                    <li>{isEng ? 'Type of personal data processed.' :
                                        'Rodzaj przetwarzanych danych osobowych'}
                                        <p>{isEng ? 'The categories of the user’s personal data processed by the Controller are the following:' :
                                            'Administrator przetwarza następujące kategorie danych osobowych użytkownika:'}</p>
                                        <ul className='sub-list'>
                                            <li>{isEng ? 'Name and surname,' :
                                                'Imię i nazwisko'}</li>
                                            <li>{isEng ? 'E-mail address.' :
                                                'Adres e-mail'}</li>
                                        </ul>
                                    </li>
                                    <li>{isEng ? 'Period of personal data storing' : 'Okres archiwizacji danych osobowych'}
                                        <p>{isEng ? ' Personal data of users are stored by the Controller.' :
                                            'Dane osobowe użytkowników przechowywane są przez Administratora:'}</p>
                                        <ul className='sub-list'>
                                            <li>{isEng ? 'if the data are processed on the basis of performance of a contract, for no longer than is necessary for the performance of the contract, and after that time, for a period corresponding to the limitation period. Unless a special provision says otherwise, the limitation period is six years, and for claims for periodic benefits and claims related to running a business activity - three years.' :
                                                'w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.'}</li>
                                            <li>{isEng ? 'if the data are processed on the basis of a consent, as long as the consent is not revoked, and after the consent is revoked: for a period of time corresponding to the limitation period that may be brought by the Controller and that can be made against it. Unless a special provision says otherwise, the limitation period is six years, for claims for periodic benefits and claims related to running a business – three years.' :
                                                'w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a dla roszczeń o świadczenia okresowe oraz roszczeń związanych z prowadzeniem działalności gospodarczej – trzy lata.'}</li>
                                        </ul>
                                    </li>
                                    <li>{isEng ? 'When users use the website, additional information may be collected, in particular: the IP address assigned to the user’s computer or the external IP address of the Internet provider, domain name, browser type, access time, type of operating system.' :
                                        'Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w szczególności: adres IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.'}</li>
                                    <li>{isEng ? 'Navigational data, including information about links they decide to click or other Website activity, may also be collected from the user. The legal basis for that type of activity is the legitimate interest of the Controller (Art. 6 sec. 1 lit. f GDPR) in facilitating the use of services provided by electronic means and improving the functionality of those services.' :
                                        'Od użytkowników mogą być także gromadzone dane nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.'}</li>
                                    <li>{isEng ? 'Providing any personal data by the user is voluntary.' :
                                        'Podanie danych osobowych przez użytkownika jest dobrowolne.'}</li>
                                    <li>{isEng ? 'Personal data will also be processed in an automated manner in the form of profiling, provided that the user gives consent pursuant to Art. 6 sec 1 lit. a GDPR. As a result of profiling, a given person will be assigned a profile in order to make related decisions or analysis or predict their preferences, behaviours, and attitudes.' :
                                        'Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i postaw.'}</li>
                                    <li>{isEng ? 'The Controller exercises due diligence to protect the interests of data subjects' :
                                        'Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są:'}
                                        <p>{isEng ? 'and in particular ensures that the collected data are' :
                                            'Dane osobowe użytkowników przechowywane są przez Administratora:'}</p>
                                        <ul className='sub-list'>
                                            <li>{isEng ? 'processed in accordance with the law,' :
                                                'przetwarzane zgodnie z prawem,'}</li>
                                            <li>{isEng ? 'collected for specified and legitimate purposes and not further processed in a manner that is incompatible with those purposes,' :
                                                'zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,'}</li>
                                            <li>{isEng ? 'adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed and kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed.' :
                                                'merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.'}</li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li className='main-list'>{isEng ? 'Personal data sharing' :
                                'Udostępnianie danych osobowych'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'Personal data of users are transferred to service providers used by the Controller when running the website. Depending on contractual arrangements and circumstances, service providers the personal data are transferred to are subject to the Controller’s instructions as to the purposes for which and the means by which those data are processed (data processors) or determine the purposes for which and the means by which those data are processed (data controllers).' :
                                        'Dane osobowe użytkowników przekazywane są dostawcom usług, z których korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i okoliczności, albo podlegają poleceniom Administratora co do celów i sposobów przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).'}</li>
                                    <li>{isEng ? 'Personal data of Customers are stored only within the European Economic Area (EEA).' :
                                        'Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).'}</li>
                                </ol>
                            </li>
                            <li className='main-list'>{isEng ? 'Right to control, right of access, and right to rectification of your own data' :
                                'Prawo kontroli, dostępu do treści własnych danych oraz ich poprawiania'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'The data subject has the right to access their personal data and the right to request from the Controller rectification or erasure of personal data or restriction of processing, the right to data portability, the right to object, the right to withdraw their consent at any time without affecting the lawfulness of processing based on consent before its withdrawal.' :
                                        'Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.'}</li>
                                    <li>{isEng ? 'Legal grounds for the Customer’s request:' :
                                        'Podstawy prawne żądania użytkownika:'}
                                        <ul className='sub-list'>
                                            <li>{isEng ? 'Right of access – Art. 15 GDPR.' :
                                                'Dostęp do danych – art. 15 RODO'}</li>
                                            <li>{isEng ? 'Right to rectification – Art. 16 GDPR.' :
                                                'Sprostowanie danych – art. 16 RODO.'}</li>
                                            <li>{isEng ? 'Right to erasure (‘right to be forgotten’) – Art. 17 GDPR.' :
                                                'Usunięcie danych (tzw. prawo do bycia zapomnianym) – art. 17 RODO.'}</li>
                                            <li>{isEng ? 'Restriction of processing – Art. 18 GDPR.' :
                                                'Ograniczenie przetwarzania – art. 18 RODO.'}</li>
                                            <li>{isEng ? 'Data portability – Art. 20 GDPR.' :
                                                'Przeniesienie danych – art. 20 RODO.'}</li>
                                            <li>{isEng ? 'Right to object – Art. 21 GDPR' :
                                                'Sprzeciw – art. 21 RODO'}</li>
                                            <li>{isEng ? 'Right to withdraw consent – Art. 7(3) GDPR.' :
                                                'Cofnięcie zgody – art. 7 ust. 3 RODO.'}</li>
                                        </ul>
                                    </li>
                                    <li>{isEng ? 'In order to exercise the rights referred to in point 2, you can send an appropriate e-mail to the following address: biuro@digitalbuildingdata.com.' :
                                        'W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e-mail na adres: biuro@digitalbuildingdata.com.'}</li>
                                    <li>{isEng ? 'If the user wishes to exercise (one of) their rights referred to in point 2, the Controller shall immediately grant the request or refuse to grant it, but not later than within a month after receiving the request. If, however, due to the complex nature of the request or the number of requests, the Controller is unable to grant the request within a month, it will grant it within the next two months after informing the user within a month of receiving the request about the intention to extend the deadline and the reasons for that.' :
                                        'W sytuacji wystąpienia przez użytkownika z uprawnieniem wynikającym z powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak – z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio w terminie miesiąca od otrzymania żądania – o zamierzonym przedłużeniu terminu oraz jego przyczynach.'}</li>
                                    <li>{isEng ? 'If it is found that personal data are processed in violation of the GDPR provisions, the data subject has the right to bring a complaint to the President of the Personal Data Protection Office.' :
                                        'W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.'}</li>
                                </ol>
                            </li>
                            <li className='main-list'>{isEng ? 'Cookies' : 'Pliki Cookies'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'The Controller website uses cookies.' :
                                        'Strona Administratora używa plików cookies.'}</li>
                                    <li>{isEng ? 'Installation of cookies is necessary for the provision of website services. Cookies contain information necessary for the website to work properly and allow the Data Controller\’s to compile general statistics of website visits.' :
                                        'Informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one także możliwość opracowywania ogólnych statystyk odwiedzin strony internetowej.'}</li>
                                    <li>{isEng ? 'The website uses functional and configurable types of cookies' :
                                        'W ramach strony stosowane są funkcjonalne i konfiguracyjne rodzaje plików „cookies”'}</li>
                                    <li>{isEng ? 'The Controller uses its own cookies to better assess how the user interacts with the content of the website. The files gather information on how the user uses the website, the type of website the user was redirected from and the number of visits and the duration of the user\’s visit on the website. That information does not register specific personal data of the user, but is used to compile statistics on the website use.' :
                                        'Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez użytkownika, typie strony, z jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie wizyty użytkownika na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych użytkownika, lecz służą do opracowania statystyk korzystania ze strony.'}</li>
                                    <li>{isEng ? 'The user has the right to choose whether cookies can be placed on their computer by selecting them in the window of their browser. Detailed information on the possibilities and methods of handling cookies is available in the software (web browser) settings.' :
                                        'Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich uprzedni wybór w oknie swojej przeglądarki.  Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).'}</li>
                                </ol>
                            </li>
                            <li className='main-list'>{isEng ? 'Final provisions' :
                                'Postanowienia końcowe'}
                                <ol className='sub-list'>
                                    <li>{isEng ? 'The Controller implements appropriate technical and organizational measures to ensure a level of security appropriate to the risk and categories of data protected, and in particular, protects the data against unauthorized disclosure, removal by an unauthorized person, unlawful processing and alteration, loss, damage, or destruction.' :
                                        'Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.'}</li>
                                    <li>{isEng ? 'The Controller provides appropriate technical measures to prevent unauthorized persons from acquiring and altering the personal data sent electronically.' :
                                        'Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.'}</li>
                                    <li>{isEng ? 'In matters not covered by this Privacy Policy, the provisions of the GDPR and other relevant provisions of Polish law shall apply accordingly.' :
                                        'W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.'}</li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Rodo;